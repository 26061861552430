import React from "react";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import LoginForm from '../components/Login/LoginForm';
import FullButton from '../components/Buttons/FullButton';
import LoginPageImage from "../assets/img/LoginPageImage.webp";

export default function Landing() {
  return (
  <>
     <Wrapper id="home" className="container flexSpaceCenter">
        <InnerWrapper>
          <LeftSide>
                <Img src={LoginPageImage} style={{zIndex: 9}} />
          </LeftSide>
          <RightSide class="flexSpaceCenter">
               <h1> NestFavor </h1>
               <LoginForm/>
          </RightSide>
        </InnerWrapper>
     </Wrapper>
  </>
  )
};

const Wrapper = styled.section`
  width: 100%;
  background-color: #99606c;
  height: 100vh;
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const InnerWrapper =  styled.section`
   width: 95%;
   background-color: white;
   height: 85%;
   margin: auto;
   border-radius: 15px;
   display: flex;
   @media (max-width: 960px) {
    flex-direction: column;
   }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  padding:100;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const FormWrapper = styled.div`
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  @media (max-width: 1200px) {
  }
`;