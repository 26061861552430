import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

const LineShort = styled.span`
    border-top: 4px solid rgb(224, 148, 116);
    border-radius: 2px;
    width: 40px;
    transition: width 0.5s ease;

    ${Container}:hover & {
        width: 80px;
    }
`;

const LineLong = styled.span`
    border-top: 4px solid rgb(224, 148, 116);;
    border-radius: 2px;
    width: 80px;
    transition: width 0.5s ease;

    ${Container}:hover & {
        width: 40px;
    }
`;

const Space = styled.span`
    width: 5px;
`;

const Text = styled.span`
    color: rgb(224, 148, 116);
    font-size: 15px;
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: 500!important;
`;

const SectionHeaderText = ({ children }) => (
    <Container>
        <LineShort />
        <Space />
        <LineLong />
        <Text>{ children }</Text>
    </Container>
);

export default SectionHeaderText;
