import React from "react";
import styled from "styled-components";
// Components
import FeatureTable from "../Elements/FeatureTable";
import SectionHeaderText from "../Elements/SectionHeaderText";

export default function KeyFeatures() {
  return (
    <Wrapper className="container flexSpaceCenter" id="pricing">
      <div className="whiteBg">
        <div>
          <br/>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <FeatureTable
                icon="cashback"
                title="Cashback Platform"
                text="Enables users to earn cashback on purchases made through the platform, enhancing savings on every transaction."
              />
            </TableBox>
            <TableBox>
              <FeatureTable
                icon="creditCard"
                title="Credit Card Optimization"
                text="Recommends the best credit card to use at specific stores to maximize cashback rewards."
              />
            </TableBox>
            <TableBox>
              <FeatureTable
                icon="rebates"
                title="Rebates and Tax Credit Dashboard"
                text="Users can enter their home address to access a list of applicable rebates and tax credits."
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 80%;
`;

const AboutUsSection = styled.div`
  margin: 10px 0;
  padding: 100px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




