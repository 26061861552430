import React from "react";
import styled from "styled-components";
import { Link, Element } from 'react-scroll';
// Components
import FullButton from "../Buttons/FullButton";
import HighlightedText  from "../Elements/HighlightedText";
// Icons
import { IconBellRinging } from '@tabler/icons-react';
import CashBackRewardsImage from "../../assets/img/cashbacks_rewards_illustration.jpeg";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide>
        <div>
          <HeaderTagLine className="font60 heading semiBold">
                Maximize your <HighlightedText> home savings </HighlightedText> with Nestfavor
          </HeaderTagLine>
          <HeaderP className="font16 regular">
            <p> Unlock exclusive cashback, rewards, and rebates on your home-related purchases. </p>
          </HeaderP>
          <StyledLink to="interest-signup" smooth={true} duration={500} offset={-90}>
              <BtnWrapper>
                <FullButton title="Join waitlist" />
              </BtnWrapper>
          </StyledLink>
          <PreLaunchTagline className="font16 regular">
            <span><IconBellRinging color="#6e8f86" size={20} /> </span>

            <LaunchOfferLine>Exclusive pre-launch offer of 1% additional cashback on your home purchases online</LaunchOfferLine>
          </PreLaunchTagline>
        </div>
      </LeftSide>
      <RightSide>
           <RightSideImage src={CashBackRewardsImage} />
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 80%;
  margin-top: 70px;
  min-height: 600px;
  gap:64px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (max-width: 560px) {
    gap: 0px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const PreLaunchTagline = styled.div`
    justify-content:
    flex-start;
    gap:16px;
    align-items:center;
    display:flex;
    margin-top:20px;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
    @media (max-width: 560px) {
        gap:0px;
    }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

/* TODO: Cleanup later */
const Grid = styled.div`
    gap:16px;
    padding-left:0px;
    padding-right:0px;
    flex-direction:column;
    flex:1 1 0%;
    display:flex;
    position:relative;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

/* TODO: Cleanup later */
const GridTopColumn = styled.div`
    gap:16px;
    justify-content:space-between;
    align-items:center;
    display:flex;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

/* TODO: Cleanup later */
const GridBottomColumn = styled.div`
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

/* TODO: Cleanup later */
const GridImage1 = styled.img`
    visibility: visible;
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
    opacity: 1;
    animation-duration:0.3s;
    animation-delay:0.2s;
    animation-name:fadeInUp;
    opacity:1;
    margin-top:96px;
    border-radius:12px;
    width: 50%;
    height: auto;
    max-width:100%;
    display:block;
    vertical-align:middle;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;"
`;

/* TODO: Cleanup later */
const GridImage2 = styled.img`
    visibility: visible;
    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-name: fadeInUp;
    opacity: 1;
    animation-duration:0.3s;
    animation-delay:0.3s;
    animation-name:fadeInUp;
    opacity:1;
    border-radius:12px;
    width: 50%;
    height: auto;
    max-width:100%;
    display:block;
    vertical-align:middle;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

/* TODO: Cleanup later */
const GridImage3 = styled.img`
    visibility: visible;
    animation-duration: 0.3s;
    animation-delay: 0.4s;
    animation-name: fadeInUp;
    opacity: 1;
    animation-duration:0.3s;
    animation-delay:0.4s;
    animation-name:fadeInUp;
    opacity:1;
    border-radius:12px;
    width: 100%;
    height: auto;
    max-width:100%;
    display:block;
    vertical-align:middle;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const RightSideImage = styled.img`
    visibility: visible;
    border-radius:12px;
    height: auto;
    max-width:100%;
    display:block;
    vertical-align:middle;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const HeaderTagLine = styled.h2`
    color: rgba(30,41,59);
     @media (max-width: 560px) {
        margin-top: -10px;
     }
`;

const HeaderP = styled.div`
  margin-top: 10px;
  max-width: 470px;
  padding: 15px 0 25px 0;
  line-height: 22px;
  color: rgb(51, 65, 85);
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const LaunchOfferLine = styled.span`
    color: #6e8f86;
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;