// rgb(249, 249, 249)

import React from "react";
import styled from "styled-components";
import HighlightedText from "../Elements/HighlightedText";
import SectionHeaderText from "../Elements/SectionHeaderText";

export default function AboutNestFavor() {
  return (
    <>
    <HeaderDiv className="container flexSpaceCenter">
        <SectionHeaderText>Key features</SectionHeaderText>
    </HeaderDiv>
    <br/>
    <Wrapper id="aboutNestFavor" className="flexSpaceCenter">
      <AboutUsSection className="whiteBg">
          <Text className="font16 regular">
           Nestfavor is an innovative online platform designed to help homeowners maximize their savings on home-related purchases. By integrating cashback opportunities with strategic credit card usage, Nestfavor offers an optimized shopping experience. Our unique rebates dashboard guides you to a comprehensive range of financial incentives for your home purchases.
          </Text>
      </AboutUsSection>
    </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const AboutUsSection = styled.div`
  margin: 10px 0;
  width: 70%;
  padding: 10px;
  margin: 0 auto;
  @media (max-width: 560px) {
      padding: 10px;
      width: 80%;
      margin: 0 auto;
  }
`;

const HeaderDiv = styled.div`
    width: 80%

`;

const Text = styled.p`
   line-height: 22px;
   color: rgb(51, 65, 85);
`;