import React from "react";
import BrandsGrid from '../components/Elements/BrandsGrid';
import MemberNavBar from "../components/Nav/MemberNavBar";
import styled from 'styled-components';


export default function Home() {
    return (
        <>
            <MemberNavBar />
            <p> Hello text </p>
            <InfoParagraph>
                Explore our curated selection of home and lifestyle brands and start earning cashback with every purchase.
            </InfoParagraph>
            <BrandsGrid />
        </>
    );
};

export const InfoParagraph = styled.p`
  color: grey; // A neutral, readable color
  font-size: 1.2rem; // Appropriate font size for readability
  line-height: 1.6; // Spacing between lines for better readability
  text-align: center; // Center-align the text for focus
  margin: 80px auto; // Top and bottom margin for spacing, auto sides for centering
  margin-bottom: 40px;
  max-width: 50%; // Maximum width to maintain readability on wider screens
  padding: 0 10px; // Padding on the sides if needed

  // Responsive adjustments (optional)
  @media (max-width: 768px) {
    font-size: 0.9rem; // Slightly smaller font on smaller screens
    max-width: 90%; // Allow more width on smaller screens
  }
`;

