import React, {useState} from "react";
import styled from "styled-components";

import SectionHeaderText from "../Elements/SectionHeaderText";
import HighlightedText from "../Elements/HighlightedText"

import FAQComponent from '../Elements/FAQComponent';

import axios from 'axios';

export default function Contact() {

  const [formData, setFormData] = useState({
      fname: '',
      email: '',
      message: ''
  });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      let data = JSON.stringify({
        email: formData.email,
        fname: formData.fname,
        message: formData.message
      });
      console.log("form data for submission:", data);

      let config = {
        method: 'post',
        url: 'https://api.nestfavor.com/api/feedback',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data
      };
      try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));
        alert('Feedback sent successfully!');
        setFormData({
          email: '',
          fname: '',
          message: ''
        });
      }
      catch (error) {
         console.log(error);
         alert('Failed to send feedback');
      }
  };


  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>

            <FAQContainer className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                 <SectionHeaderText>FAQ's</SectionHeaderText>
                 <br/>
                 <h2 className="font40 heading extraBold">
                        Questions, <HighlightedText> Answered </HighlightedText>
                 </h2>
                 <FAQComponent/>
            </FAQContainer>

            <FeedBackContainer className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <br/>
              <br/>
              <FeedBackHeader className="font40 heading extraBold"> feedback </FeedBackHeader>
              <Form onSubmit={handleSubmit}>
                <input type="text" placeholder="First name" id="fname" name="fname" className="font15"
                    value={formData.fname}
                    onChange={handleChange} />
                <input type="text" placeholder="Email" id="email" name="email" className="font15"
                    value={formData.email}
                    onChange={handleChange} />
                <textarea placeholder="Please enter your feedback or features you would like to see" rows="4" cols="50" type="text" id="message" name="message" className="font15"
                    value={formData.message}
                    onChange={handleChange}/>
                <SumbitWrapper className="flex">
                    <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
                </SumbitWrapper>
              </Form>
            </FeedBackContainer>


          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 80px;
  @media (max-width: 560px) {
    margin-top: 40px;
  }
`;

const FAQContainer = styled.div`
    display: block;
    width: 100%;
    padding: 40px;
`;

const FeedBackContainer = styled.div`
    width: 100%;
    padding: 40px;
    @media (max-width: 560px) {
        padding-top: 0px;
        margin-top: -10px;
    }
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  font-family: "Roboto", sans-serif;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
    font-family: "Roboto", sans-serif;
    &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          outline: none;
          box-shadow: none;
          -webkit-text-fill-color: #000; /* Change text color to desired color */
          -webkit-box-shadow: 0 0 0px 1000px transparent inset; /* Change background color to transparent */
          transition: background-color 5000s ease-in-out 0s; /* Delay to remove background color transition */
        }
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid rgb(224, 148, 116) !important;
  background-color: rgb(224, 148, 116) !important;
  border-radius: 25px !important;
  width: 100% !important;
  padding: 10px !important;
  height: 40px !important;
  outline: none !important;
  color: #fff !important;
  :hover {
    background-color: #580cd2 !important;
    border: 1px solid #7620ff !important;
    color: #fff !important;
  }
  @media (max-width: 991px) {
    margin: 0 auto !important;
  }
`;

const FeedBackHeader = styled.h2`
    color: rgb(224, 148, 116);
`;

const SumbitWrapper = styled.div`

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;










