import React, { useState } from 'react';
import styled from 'styled-components';

const FAQSection = styled.section`
  margin: 50px auto;
  background-color: #fff;
  border: none;

  @media (max-width: 860px) {
    padding: 15px;
  }
`;

const FAQItem = styled.div`
  margin-top: 20px;
`;

const FAQQuestion = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: rgb(36, 58, 67);
  transition: background-color 0.3s;
  color: #fff;

  span {
    flex-grow: 1;
  }

  button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: rgb(224, 148, 116);
  }
`;

const FAQAnswer = styled.div`
  padding: 15px;
  display: none;
  background-color: rgb(241, 241, 241);
  color: rgb(51, 65, 85);
  line-height: 22px;
  font-size: 1rem;
  border: none;

  &.activeQ {
    display: block;
    border-bottom: 1px solid rgb(241, 241, 241);
  }
`;

const FAQComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQSection>
      <FAQItem className={activeIndex === 0 ? 'activeQ' : ''}>
        <FAQQuestion onClick={() => toggleFAQ(0)}>
          <span>Which product categories can I earn cashback on?</span>
          <button>{activeIndex === 0 ? '▲' : '▼'}</button>
        </FAQQuestion>
        <FAQAnswer className={activeIndex === 0 ? 'activeQ' : ''}>
          <p>From stylish furnishings to essential garden tools and cozy pet accessories, our platform covers the full spectrum of home needs, offering cashback for every brand featured.</p>
        </FAQAnswer>
      </FAQItem>
      <FAQItem className={activeIndex === 1 ? 'activeQ' : ''}>
        <FAQQuestion onClick={() => toggleFAQ(1)}>
          <span>When can I start enjoying my cashback rewards?</span>
          <button>{activeIndex === 1 ? '▲' : '▼'}</button>
        </FAQQuestion>
        <FAQAnswer className={activeIndex === 1 ? 'activeQ' : ''}>
          <p>Once the cashback platform is launched, we enable the cashback earned after the locking period. Locking period could be between 14-29 days.</p>
        </FAQAnswer>
      </FAQItem>
    </FAQSection>
  );
};

export default FAQComponent;
