import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import CashbackSavingsIcon from "../../assets/img/savings_v1.png";
import CreditCardHeartIcon from "../../assets/img/credit_card_heart_v1.png";
import RebatesIcon from "../../assets/img/rebates_v1.png";

export default function FeatureTable({ icon, price, title, text,  offers, action }) {
  let getIcon;

  switch (icon) {
    case "cashback":
      getIcon = <IconImage src={CashbackSavingsIcon} />;
      break;
    case "creditCard":
      getIcon = <IconImage src={CreditCardHeartIcon} />;
      break;
    case "rebates":
      getIcon = <IconImage src={RebatesIcon} />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <ImageDiv className="flexSpaceCenter">
        {getIcon}
      </ImageDiv>
      <div style={{ margin: "30px 0" }}>
        <MainText className="font18 semiBold">{title}</MainText>
        <br/>
        <SubText className="font16">{text}</SubText>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const ImageDiv = styled.div`
  max-width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const MainText = styled.p`
    text-align: center;
`;

const SubText = styled.p`
    line-height: 20px;
    color: rgb(51, 65, 85);
    text-align: center;
`;

const IconImage = styled.img`
    visibility: visible;
    height: 48px;
`;