import React from "react";
import styled from "styled-components";

export default function InputText({ type, id, name, placeholder, onChange, value }) {
  return (
    <StyledInput
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
    />
  );
}

const StyledInput = styled.input`
    width: 80%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    transition: 0.5s;
    outline: none;
    border-radius: 5px;
    /* Change background color on autofill */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important; // Change the white color as needed
      -webkit-text-fill-color: black !important; // Change the text color as needed
    }

    /* Firefox */
    &:-moz-ui-invalid {
      background-color: white !important; // Change the white color as needed
      color: black !important; // Change the text color as needed
    }

  &:focus {
    border: 1px solid #555;
  }
`;