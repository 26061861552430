import styled from 'styled-components';

const HighlightContainer = styled.span`
  display: inline-block;
  position: relative;

  &::before {
    content: " ";
    display: block;
    height: 60%;
    width: 104%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #e09474;
    transform: rotate(-4deg);
    top: 10px;
    left: -5px;
    padding: 10px 3px 3px 10px;
    color: pink;
  }
`;

const HighlightText = styled.span`
  position: relative;
`;

const HighlightedText = ({ children }) => (
  <HighlightContainer>
    <HighlightText>{children}</HighlightText>
  </HighlightContainer>
);

export default HighlightedText;
