import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import PrelaunchOffer from '../components/Sections/PrelaunchOffer';
import AboutUs from "../components/Sections/AboutUs";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import KeyFeatures from "../components/Sections/KeyFeatures";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import HowItWorks from "../components/Sections/HowItWorks";
import AboutNestFavor from "../components/Sections/AboutNestFavor";
import SignUpForm from "../components/Sections/SignUpForm";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <AboutNestFavor />
      <KeyFeatures />
      <AboutUs />
      <HowItWorks />
      <PrelaunchOffer />
      <SignUpForm/>
      <Contact />
      <Footer />
    </>
  );
}


