import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputText  from '../Elements/InputText';
import styled from "styled-components";

const LoginForm = ({ onLogin }) => {
  const navigateTo = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await onLogin(email, password);
    if (success) {
      console.log("Login successful");
      navigateTo('/home');
      // Redirect user or update UI accordingly
    } else {
      console.log("Login failed");
      // Show error message
    }
  };

  function onLogin(email, password) {
    return true;
  }

  return (
  <>
    <p>Sign into your account</p>
    <CenteredForm onSubmit={handleSubmit}>
      <InputText
          type="text"
          id="email"
          name="email"
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
      />
       <InputText
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
       />
      <FormButton type="submit">Login</FormButton>
    </CenteredForm>
    <StyledP> Forgot password? </StyledP>
    <a href="/login">
        Don't have an account? Register here
    </a>

   </>
  );
};

export const StyledP = styled.p`
  color: #666; // Example color
  font-size: 1rem; // Example font size
  line-height: 1.5; // Example line height
  margin-top: 20px; // Space below the paragraph
  overflow: auto; // Adds a scrollbar if content overflows the fixed height
  // Add more styles as needed
`;

export const smallP = styled.div`
    font-size:0.5rem;
    color: #666;
`;

export const headerP = styled.div`
   font-size: 5rem;
   color: #196;
`;

export const CenteredForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 70%; // Adjust the width as needed for your design
    align-items: center;
    justify-content: center;
    margin-top:30px;
`;

export const FormButton = styled.button`
  display: block;
  width: auto;
  margin-top: 20px; // Adjust the space as needed
  padding: 10px 15px; // Example padding, adjust as needed
  border: none;
  background-color: black; // Example background color
  color: white; // Text color
  font-size: 16px; // Example font size
  cursor: pointer;
  border-radius: 5px; // Example border radius

  &:hover {
    background-color: #0056b3; // Darker shade on hover, adjust as needed
  }
`;

export default LoginForm;
