import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import RowBox from "../Elements/RowBox";
import SectionHeaderText from "../Elements/SectionHeaderText";
import FullButton from "../Buttons/FullButton";
import HighlightedText from "../Elements/HighlightedText"
import AboutUsIllustration from "../../assets/img/about_us_mission.jpeg";

export default function AboutUs() {
  return (
    <Wrapper id="aboutus" className="container flexSpaceCenter">
      <div className="whiteBg">
        <div>
          <div>
            <AboutUsSection className="flexSpaceCenter">
              <AddLeft>
                <SectionHeaderText> About us </SectionHeaderText>
                <br/>
                <h2 className="font40 heading extraBold">
                    Our <HighlightedText>Mission</HighlightedText> at Nestfavor
                </h2>
                <br/>
                <Para className="font16">
                   With NestFavor, enjoy the convenience of discovering your home needs from a wide range of brands in one place, along with the added perk of earning cash back on your purchases. By offering these cashbacks, we're passing along the advantages of our retail partnerships directly to you. It's our commitment to transforming your shopping journey into one that's not only effortless but also more rewarding. We are dedicated to evolving into a one-stop destination for new home buyers and enthusiasts. Our vision includes providing you with the resources and guidance you need to make your new home journey as smooth as possible.
                </Para>
                <br/>
                <Para className="font16">
                    Our model is straightforward: we share the commissions earned from our partners with you, making every purchase through nestfavor rewarding. Joining our waitlist now means you’re not just getting an extra 1% cashback on the purchases made through our cashback platform upon launch; you’re embracing a new way of smart, value-driven shopping for your home
                </Para>
               </AddLeft>
              <AddRight>
                <AddRightInner>
                  <AboutUsImage src={AboutUsIllustration}/>
                </AddRightInner>
              </AddRight>
            </AboutUsSection>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 80%;
  margin-top: 70px;
`;

const AboutUsSection = styled.div`
  padding-top: 50px;
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 0 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 20px 0;
    margin: 80px 0 0px 0;
  }
`;

const Para = styled.p`
    line-height: 24px;
    color: rgb(51, 65, 85);
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 55%;
  p {
    max-width: 100%;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: left;
    h2 {
      line-height: 3rem;
      margin: 10px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 45%;
  position: absolute;
  right: 0;
  top: 5;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AboutUsImage = styled.img`
    width: 90%;
    height: auto;
`;