import React from "react";
import styled from "styled-components";
import LogoImage from "../../assets/img/logo/nestfavor_v1.png";

function SvgComponent(props) {
  return (
    <Img src={LogoImage} />
  );
}


const Img = styled.img`
    width: 250px;
    height: 100px;
`;
export default SvgComponent;
