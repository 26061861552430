import React from 'react';
import styled from 'styled-components';

const BrandTile = ({ name, logo, website, cashback }) => {
  return (
   <CardContainer href={website} target="_blank" rel="noopener noreferrer">
         <ImageWrapper>
           <StyledImage src={logo} alt={name} />
         </ImageWrapper>
         <div>
           <StyledH4>{name}</StyledH4>
           <StyledP>{cashback}% cashback</StyledP>
         </div>
   </CardContainer>
  );
};

export const CardContainer = styled.a`
  border: 0px solid #ccc;
  padding: 20px;
  margin: 10px;
  display: flex; // Set to flex for horizontal layout
  align-items: center; // Align items vertically in the center
  gap: 20px; // Space between image and details

  text-decoration: none; // Removes underline from link
  color: inherit; // Inherits text color, adjust as needed

  &:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,0.2); // Example hover effect, adjust as needed
    transition: box-shadow 0.3s ease-in-out;
    color: inherit;
  }

`;

// Styled div for the image
export const ImageWrapper = styled.div`
  background-color: white;
  padding: 10px; // Adjust padding as needed
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Example shadow, adjust as needed
  border-radius: 5px;
  display: flex; // To center the image within this div
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
`;

// Styled image component
export const StyledImage = styled.img`
  width: 140px;
  height: 60px;
`;

// Styled h4 component for the name
export const StyledH4 = styled.h4`
  margin: 0; // Remove default margin
`;

// Styled paragraph for details like cashback
export const StyledP = styled.p`
  margin: 0; // Remove default margin
  color: #4CAF50; // A pleasant green color for text
`;

// Styled anchor tag for the website link
export const StyledA = styled.a`
  text-decoration: none; // Example style, adjust as needed
  color: blue; // Example style, adjust as needed
  &:hover {
    text-decoration: underline;
  }
`;

export default BrandTile;
