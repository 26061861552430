import React, { useEffect, useState } from 'react';
import BrandTile from './BrandTile';
import styled from 'styled-components';

const BrandsGrid = () => {
  const [brands, setBrands] = useState([]);

  const [error, setError] = useState('');

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic dXNlcjpjNjBmZjY3ZS01ZjU1LTQ5OWQtYTRhZC1hMGYyOGZiNzdkOTM=");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("http://127.0.0.1:8080/api/campaigns", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(result => {
                setBrands(result); // Assuming the result is the array of campaigns
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []); // Empty dependency array means this effect runs once on mount


  return (
    <>
        <GridContainer>
            {brands.map(brand => (
               <BrandTile key={brand.advertiserId} name={brand.advertiserName} logo={brand.campaignLogoUri} website={brand.trackingLink} cashback={brand.userCashbackRate} />
            ))}
        </GridContainer>
    </>
  );
};

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);    /* Creates 5 columns with equal width */
  gap: 5px;                                /* Adjust the gap between grid items as needed */
  margin: 50px;
  /* Add any additional styles for padding, margin, etc., as needed */
`;


export default BrandsGrid;
