import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import RowBox from "../Elements/RowBox";
import SectionHeaderText from "../Elements/SectionHeaderText";
import FullButton from "../Buttons/FullButton";
import HighlightedText from "../Elements/HighlightedText"
// Assets
import AboutUsIllustration from "../../assets/img/about_us_mission.jpeg";

export default function HowItWorks() {
  return (
    <Wrapper id="aboutus" className="container flexSpaceCenter">
      <div className="whiteBg">
        <div>
          <SectionHeaderText> How it works </SectionHeaderText>
          <HowItWorksRow className="flex">
            <HowItWorksBoxWrapper>
              <RowBox
                icon="register"
                title="Effortless sign-up"
                subtitle="Signup for free and gain access to cashback offers across an extensive selection of home brands."
              />
            </HowItWorksBoxWrapper>
            <HowItWorksBoxWrapper>
              <RowBox
                icon="shopping"
                title="Explore & shop"
                subtitle="Explore your favorite brands all offering rewarding cashback and our dashboards for applicable rebates"
              />
            </HowItWorksBoxWrapper>
            <HowItWorksBoxWrapper>
              <RowBox
                icon="redeem"
                title="Earn cashback"
                subtitle="Receive cashback on all purchases redirected from our platform, redeemable directly to your bank or PayPal."
              />
            </HowItWorksBoxWrapper>
          </HowItWorksRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 80%;
  margin-top: 70px;
`;
const HowItWorksRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const HowItWorksBoxWrapper = styled.div`
  width: 30%;
  margin-right: 5%;
  padding-top: 80px;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding-top: 40px;
  }
`;

const AboutUsSection = styled.div`
  margin: 10px 0;
  padding-top: 100px;
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 0 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const Para = styled.p`
    line-height: 24px;
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 55%;
  p {
    max-width: 90%;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 45%;
  position: absolute;
  right: 0;
  top: 5;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AboutUsImage = styled.img`
    width: 90%;
    height: auto;
`;