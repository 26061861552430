import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import RegisterIcon from "../../assets/img/register.png";
import RedeemIcon from "../../assets/img/redeem.png";
import ShoppingIcon from "../../assets/img/shopping.png";

export default function RowBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "register":
      getIcon = <IconImage src={RegisterIcon} />;
      break;
    case "shopping":
      getIcon = <IconImage src={ShoppingIcon} />;
      break;
    case "redeem":
      getIcon = <IconImage src={RedeemIcon} />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }


  return (
    <Wrapper className="flexSpaceCenter flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font18 semiBold">{title}</TitleStyle>
      <SubtitleStyle className="font16">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: auto;
  }
`;
const TitleStyle = styled.p`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color:rgb(51, 65, 85);
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  line-height: 24px;
  color:rgb(51, 65, 85);
`;
const IconImage = styled.img`
    visibility: visible;
    height: 48px;
`;