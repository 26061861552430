import React, { useState } from "react";
import styled from "styled-components";
import HighlightedText from "../Elements/HighlightedText";
import SectionHeaderText from "../Elements/SectionHeaderText";
import axios from 'axios';

export default function SignUpForm() {

    const [email, setEmail] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
      event.preventDefault();

      const data = new URLSearchParams();
      data.append('email', email);
      data.append('referralCode', referralCode);

      try {
        const response = await axios.post('https://api.nestfavor.com/interest/signup', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        console.log("response:",response);

        if (response.status === 200) {
          setMessage('Signup successful!');
        } else {
          setMessage('Signup failed. Please try again.');
        }
      } catch (error) {
        setMessage('Error occurred. Please try again.');
      }
    };

  return (
     <Wrapper name="interest-signup" className="container">
        <SignUpContainer className="blueBg">
          <Header>
                <h2 className="font30 heading extraBold">Sign up now to save more! </h2>
           </Header>
          <Form className="container" onSubmit={handleSubmit}>
            <input type="text" id="email" placeholder="Enter your email" name="email" className="font20 extraBold"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required />
            <ButtonInput type="submit" value="Join waitlist" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
          </Form>

          {message &&
            <StatusMessage>
                <Message>{message}</Message>
            </StatusMessage>
          }
        </SignUpContainer>
      </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 50px;
  color: white;
`;

const SignUpContainer = styled.div`
  padding: 40px;
  padding-bottom: 10px;
  border-radius: 15px;
  background: linear-gradient(135deg, rgba(54, 94, 109, 1) 10%,  rgba(36, 58, 67, 1) 100%);
`;

const Header = styled.div`
   line-height: 22px;
   display: flex;
   color: white;
   align-items: center;
   justify-content: center;
`;

const Form = styled.form`
  margin-top: 20px;
  padding: 40px 0 0 0;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 64px;
  flex-direction: row;
  @media (max-width: 560px) {
      gap: 4px;
      flex-direction: column;
  }

  input,
  textarea {
    color: white;
    width: 30%;
    @media (max-width: 560px) {
       width: 70%;
    }
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 50px;
    margin-bottom: 30px;
    text-align: center;
    &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              outline: none;
              box-shadow: none;
              -webkit-box-shadow: 0 0 0px 1000px transparent inset; /* Change background color to transparent */
              transition: background-color 5000s ease-in-out 0s; /* Delay to remove background color transition */
            }
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const ButtonInput = styled.input`
  border: 1px solid rgba(224, 148, 116, 255) !important;
  background-color: rgba(224, 148, 116, 255) !important;
  border-radius: 25px;
  width: 100%;
  padding: 10px;
  outline: none;
  color: #fff;
  :hover {
    background-color: rgba(224, 148, 116, 255);
    border: 1px solid rgba(224, 148, 116, 255);
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
  font-weight: 600!important;
`;

const SumbitWrapper = styled.div`
    margin-bottom: 10px;
`;

const StatusMessage = styled.div`
    display: flex;
    justify-content: center;  /* Horizontal centering */
    align-items: center
    color: rgb(224, 148, 116);
`;

const Message = styled.p`
    color: rgb(224, 148, 116);
`;

