import React from "react";
import styled from "styled-components";
// Components
import SectionHeaderText from "../Elements/SectionHeaderText";
import HighlightedText from "../Elements/HighlightedText"
//assets
import PromotionImage from "../../assets/img/promotion.png";
import LoyaltyProgramImage from "../../assets/img/loyalty_program.jpeg";


export default function PrelaunchOffer() {
    return (
        <Wrapper className="container flexSpaceCenter">
            <LeftSide>
                <InnerDiv>
                    <PrelauchOfferImage src={LoyaltyProgramImage} />
                </InnerDiv>
            </LeftSide>
            <RightSide>
                <SectionHeaderText> Promotion </SectionHeaderText>
                <LaunchOfferHeading>
                    <InnerSpan className="font40 heading extraBold">
                        Exclusive pre-launch Offer: Secure an <HighlightedText> extra 1% </HighlightedText> cashback!
                    </InnerSpan>
                </LaunchOfferHeading>
                <LaunchOfferSubSection>
                    <InnerSpan className="font16">
                        As a special thank you for joining our community early, we’re offering an additional 1% cashback on all purchases made through nestfavor. This is your opportunity to maximize savings on your home needs from day one. Sign up now to lock in your bonus and be the first to know when we launch. Your home, your savings, elevated.
                    </InnerSpan>
                </LaunchOfferSubSection>
            </RightSide>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 80%;
  gap:64px;
  margin-top: 60px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (max-width: 560px) {
     gap: 0px;
     order: 1;
  }
`;

const LeftSide = styled.div`
  width: 45%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    text-align: center;
  }
`;

const RightSide = styled.div`
  width: 55%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
`;

const LabelTag = styled.div`
    visibility: visible;
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-name: fadeInUp;
    opacity: 1;
    animation-duration:0.3s;
    animation-delay:0s;
    animation-name:fadeInUp;
    opacity:1;
    font-size:12px;
    line-height:16px;
    padding-bottom:6px;
    padding-top:6px;
    padding-left:16px;
    padding-right:16px;
    color:white;
    font-weight:600;
    background-color:#6e8f86;
    border-radius:9999px;
    justify-content:center;
    align-items:center;
    width: fit-content;
    display:flex;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const OuterSpan = styled.span`
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const InnerSpan = styled.span`
    display:inline-block;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const InnerDiv = styled.div`
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const LaunchOfferHeading = styled.h2`
    visibility: visible;
    color:rgb(30, 41, 59);
    font-weight:700;
    font-size:36px;
    line-height:40px;
    margin:0px;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
    margin-top: 15px;
`;

const LaunchOfferSubSection = styled.p`
    visibility: visible;
    color:rgb(51, 65, 85);
    line-height:24px;
    margin:0px;
    margin-top: 30px;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const PrelauchOfferImage = styled.img`
    visibility: visible;
    border-radius:12px;
    width: 100%;
    height: auto;
    max-width:100%;
    display:block;
    vertical-align:middle;
    border:0px solid rgb(229, 231, 235);
    box-sizing:border-box;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const HeaderSpanText = styled.span`
    color: rgb(224, 148, 116);
    font-weight: 500!important;
`;